import React from 'react';
import i18n from 'i18next';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Link
} from "react-router-dom";

interface IProps extends WithTranslation {
    user: any;
    internalUser: any;
}

interface IState {
    language: string;
}

class NavigationBar extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = { language: 'en' };
    }

    changeLanguage = (event: React.FormEvent<HTMLSelectElement>) => {
        const lng = event.currentTarget.value
        this.setState({ language: lng })
        i18n.changeLanguage(lng);
    }

    render() {
        const { language } = this.state;
        const { t } = this.props;

        return (
            <div className="navigation-bar">
                <div className="home">
                    <Link to="/">Corona Courier</Link>
                </div>
                <div className="menu">
                    <div className={'item ' + (this.props.internalUser ? '' : 'noauth')}>
                        <Link to="/">
                            { t('navigation.home') }
                        </Link>
                    </div>
                    <div className={'item ' + (this.props.internalUser ? '' : 'noauth')}>
                        <Link to="/resources">
                            { t('navigation.resources') }
                        </Link>
                    </div>
                    <div className={'item ' + (this.props.internalUser ? '' : 'noauth')}>
                        <Link to="/contact">
                            { t('navigation.contact') }
                        </Link>
                    </div>

                    <div style={{ display: this.props.internalUser ? 'block' : 'none' }} className="item">
                        <Link className="protected-link" to="/internal">
                            Internal
                        </Link>
                    </div>
                    <div style={{ display: this.props.internalUser ? 'block' : 'none' }} className="item">
                        <Link className="protected-link" to="/cc-intake">
                            Intake
                        </Link>
                    </div>
                    <select
                        value={ language }
                        onChange={ e => this.changeLanguage(e) }>
                        <option value="es">Español</option>
                        <option value="en">English</option>
                    </select>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NavigationBar)
