import React, { Suspense, useEffect, useState } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ContactPage from './external/contact-page';
import LandingPage from './external/landing-page';
import ResourcesPage from './external/resources-page';
import IntakePage from './internal/intake-page';
import InternalPage from './internal/internal-page';
import Login from './login';
import NavigationBar from './navigation-bar';

import firebaseApp from './firebase.js';
import PrivateRoute from './private-route';


const useAuth = () => {
    const [state, setState] = useState(() => {
        const user = firebaseApp.auth().currentUser;
        return { initializingUser: !user, user, internalUser: "" };
    });

    async function onChange(user: any) {
        const internalUser = await firebaseApp.database().ref('users/' + user?.uid).once('value').then(res => res.val());
        setState({ initializingUser: false, user: user, internalUser: internalUser });
    }

    useEffect(() => {
        const unsubscribe = firebaseApp.auth().onAuthStateChanged(onChange);
        return () => unsubscribe();
    }, []);

    return state;
};

const App = () => {
    const { initializingUser, user, internalUser } = useAuth();

    if (initializingUser) {
        return <div>~~Loading</div>;
    }

    return (
        <Suspense fallback="loading">
            <Router>
                <NavigationBar user={user} internalUser={internalUser} />
                <div className="page">
                    <Switch>
                        <Route path="/resources">
                            <ResourcesPage />
                        </Route>
                        <Route path="/contact">
                            <ContactPage />
                        </Route>
                        <PrivateRoute
                            path="/internal"
                            component={InternalPage}
                            user={user}
                            internalUser={internalUser}>
                        </PrivateRoute>
                        <Route exact path="/login" component={Login} />
                        <PrivateRoute
                            path="/cc-intake"
                            component={IntakePage}
                            user={user}
                            internalUser={internalUser}>
                        </PrivateRoute>
                        <Route exact path="/">
                            <LandingPage />
                        </Route>
                        <Route path="*">
                            <LandingPage />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </Suspense>
    );
};

export default App;
