import React, { Component } from 'react';
import firebaseApp from '../firebase.js';
import { Link } from 'react-router-dom';

interface InternalPageProps {
    user: any;
    internalUser: any;
}
export default class InternalPage extends Component<InternalPageProps> {

    render() {
        return (
            <div className="internal-links panes">
                <div>
                    <Link to="/">
                        <a className="auth-button" onClick={() => firebaseApp.auth().signOut()}>
                            log out
                            <img src='imgs/logout.svg' />
                        </a>
                    </Link>
                </div>
                <div className="subtitle">
                    Private part of the site
                </div>
                <div className="section">
                    <div className="resources">
                        <p>
                            <b>Passwords</b>
                            <br />
                            We currently have two email addresses + a general gmail account that has our Google voice attached to it.
                            We also have a shared account to access Airtable which contains all of our request data. In order to access the passwords
                            for these accounts, you must be added to the <b>internal docs</b> folder in our Google drive. In there, there is a document
                            called <b>Tool Access Information</b>. Please keep the information of this document private. DM Saleh or Sasha in order to get this
                            folder shared with you so that you can help out with operations!
                        </p>
                        <p>
                            <b>Google</b>
                            <br />
                            All of our documents, videos, general assets live on the CoronaCourierNYC@gmail.com account. Most everything is in the Google drive.
                            If you want access, please send Saleh or Sasha your email and asked to be given access to our passwords document. :)
                            <br />
                            Also, our Google voice number is attached to this account. If you want to help with phone intake, this is the account you will need to use.
                        </p>
                        <p>
                            <b>Airtable</b>
                            <br />
                            All of our request info now resides in Airtable. This allows us to create tables and then views on top of that data to see certain information,
                            more clearly. Video tutorials on how to use Airtable are pinned to the #ops-leaders channel. Information on accessing Airtable is in
                            our <b>Tool Access Information</b> document in our Google Drive account.
                        </p>
                        <p>
                            <b>Volunteer Interest Form</b>
                            <br />
                            Form to send to people who want to join us in volunteering! Will switch this over to be an Airtable form soon.
                            <br />
                            To share:
                            <br />
                            <a href="https://forms.gle/yWf8xDeeXj7r9MKNA">https://forms.gle/yWf8xDeeXj7r9MKNA</a>
                            <br />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
