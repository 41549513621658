import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { I18nextProvider } from "react-i18next";

import i18n from './i18n';

import App from './components/app';

import './index.scss';


ReactDOM.render(
    <I18nextProvider i18n={ i18n} >
        <Router>
            <Route path="/" component={ App } />
        </Router>
    </I18nextProvider>,
    document.getElementById('root')
);
