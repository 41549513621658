import * as firebase from 'firebase/app';

const firebaseApp = firebase.initializeApp({
    apiKey: 'AIzaSyBUnAvZdLpH78l_ELGyplR1KgAu-TSz1qE',
    authDomain: 'coronacourier.nyc',
    databaseURL: 'https://corona-couriers.firebaseio.com',
    projectId: 'corona-couriers',
    storageBucket: 'corona-couriers.appspot.com',
    messagingSenderId: '618353741057',
    appId: '1:618353741057:web:bd926455df782ee31e1f00',
});

export default firebaseApp;
