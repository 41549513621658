import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import firebaseApp from './firebase.js';

const PrivateRoute = ({ component: RouteComponent, user, internalUser, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                user ? (
                    internalUser ? (
                        <RouteComponent user={user} internalUser={internalUser} {...routeProps} />
                    ) : (
                        <div className="internal-links panes">
                            <div>
                                <Link to="/">
                                    <a className="auth-button" onClick={() => firebaseApp.auth().signOut()}>
                                        log out
                                        <img src='imgs/logout.svg' />
                                    </a>
                                </Link>
                            </div>
                        </div>
                    )
                ) : (
                    <Redirect to={'/login'} />
                )
            }
        />
    );
};

export default PrivateRoute;
