import React, { Component } from 'react';
import firebaseApp from '../firebase.js';
import { Link } from 'react-router-dom';

const DELIVERY_INTAKE_URL = "https://airtable.com/embed/shrJAlPa0QfMX5Wp0?backgroundColor=red&prefill_Status=Open";

export default class IntakePage extends Component {
    render () {
        return (
            <div className='intake-page'>
                <div>
                    <Link to="/">
                        <a className="auth-button" onClick={() => firebaseApp.auth().signOut()}>
                            log out
                            <img src='imgs/logout.svg' />
                        </a>
                    </Link>
                </div>
                <div className="subtitle">
                    Private part of the site
                </div>
                <div className='form-container'>
                    <iframe
                        title='delivery-form'
                        id='delivery-form'
                        src={ DELIVERY_INTAKE_URL }
                        width="100%"
                        height="600"
                        frameBorder={ 0 }>
                        Loading...
                    </iframe>
                </div>
            </div>
        );
    }

}
