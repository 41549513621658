import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ResourcesPage () {
    const { t } = useTranslation();
    return (
        <div className="resources-page">
            <div className="left panes">
                <div className="title">
                    {t('resources.delivery.title')}
                </div>
                <div className="subtitle">
                    {t('resources.delivery.subtitle')}
                </div>
                <div className="resources">
                    <p>
                        <a href="https://nyc.gov/getfood" target="_blank" rel="noopener noreferrer">
                            <b>{t('resources.delivery.nyc-delivery')}</b>
                        </a>
                        <br/>
                        {t('resources.delivery.nyc-delivery-d')}
                    </p>
                    <p>
                        <b>{t('resources.delivery.meals-on-wheels')}</b>
                        <br/>
                        {t('resources.delivery.meals-on-wheels-d')}
                        <br/>
                        {t('resources.delivery.meals-on-wheels-d1')}
                    </p>
                </div>

                <div className="title">
                    {t('resources.freefood.title')}
                </div>
                <div className="subtitle">
                    {t('resources.freefood.subtitle')}
                </div>
                <div className="resources">
                    <p>
                        <a href="https://www.foodbanknyc.org/get-help/" target="_blank" rel="noopener noreferrer">
                            <b>{t('resources.freefood.food-bank')}</b>
                        </a>
                        <br />{t('resources.freefood.food-bank-d')}
                    </p>
                    <p>
                        <a href="https://docs.google.com/spreadsheets/d/11sRdUqjlWg7gqZBNVq87Y7Hnfrf4s3fBnbANoxeWUSI/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
                            <b>{t('resources.freefood.soup-kitchens')}</b>
                        </a>
                        <br/>{t('resources.freefood.soup-kitchens-d')}
                    </p>
                    <p>
                        <a href="https://www.schools.nyc.gov/freemeals" target="_blank" rel="noopener noreferrer">
                            <b>{t('resources.freefood.school')}</b>
                        </a>
                        <br />{t('resources.freefood.school-d')}
                        <br />{t('resources.freefood.school-d1')}
                    </p>
                    <p>
                        <a href="https://maps.nyc.gov/foodhelp" target="_blank" rel="noopener noreferrer">
                            <b>{t('resources.freefood.foodhelp')}</b>
                        </a>
                        <br />{t('resources.freefood.foodhelp-d')}
                    </p>
                    <p>
                        <b>{t('resources.freefood.emergency-hotline')}</b>
                        <br />{t('resources.freefood.emergency-hotline-d')}
                        <br />{t('resources.freefood.emergency-hotline-d1')}
                    </p>
                    <p>
                        <a href="https://communitysolidarity.org/foodshares/bedstuy#get-food" target="_blank" rel="noopener noreferrer">
                            <b>{t('resources.freefood.bedstuy-foodshare')}</b>
                        </a>
                        <br />{t('resources.freefood.bedstuy-foodshare-d')}
                        <br />
                        <a href="https://communitysolidarity.org/combating-coronavirus" target="_blank" rel="noopener noreferrer">
                            {t('resources.freefood.bedstuy-foodshare-d1')}
                        </a>
                    </p>
                    <p>
                        <a href="https://foodnotbombs.net/new_site/map/newyork.html" target="_blank" rel="noopener noreferrer">
                            <b>NYC Food Not Bombs</b>
                        </a>
                        <br />{t('resources.freefood.food-not-bombs')}
                    </p>
                    <p>
                        <a href="https://hepfree.nyc/covid-19-coronavirus-resource-compilation/" target="_blank" rel="noopener noreferrer">
                            <b>{t('resources.freefood.hepfree')}</b>
                        </a>
                        <br />{t('resources.freefood.hepfree-d')}
                    </p>
                    <p>
                        <a href="https://www.disasterassistance.gov/get-assistance/forms-of-assistance/5769" target="_blank" rel="noopener noreferrer">
                            <b>{t('resources.freefood.dsnap')}</b>
                        </a>
                        <br />{t('resources.freefood.dsnap-d')}
                    </p>
                </div>
                <div className="title">
                    {t('resources.mutualaid.title')}
                </div>
                <div className="subtitle">
                    {t('resources.mutualaid.subtitle')}
                </div>
                <div className="resources">
                    <a href="https://docs.google.com/document/d/18WYGoVlJuXYc3QFN1RABnARZlwDG3aLQsnNokl1KhZQ/edit#heading=h.mva2lef8rehp" target="_blank" rel="noopener noreferrer">
                        <b>{t('resources.mutualaid.against')}</b>
                    </a><br /><br />
                    <a href="http://bit.ly/CoronavirusResourceKit" target="_blank" rel="noopener noreferrer">
                        <b>{t('resources.mutualaid.kit')}</b>
                    </a> <br /><br />
                    <a href="https://mutualaid.nyc/" target="_blank" rel="noopener noreferrer">
                        <b>{t('resources.mutualaid.manyc')}</b>
                    </a><br />
                    {t('resources.mutualaid.manyc-d')}<br /><br />

                    <a href="https://www.lahuelga.com/" target="_blank" rel="noopener noreferrer">
                        <b>{t('resources.mutualaid.cosecha')}</b>
                    </a><br />
                    {t('resources.mutualaid.cosecha-d')}<br /><br />

                    <a href="https://www.undocuscholars.com/" target="_blank" rel="noopener noreferrer">
                        <b>{t('resources.mutualaid.undocuscholars')}</b>
                    </a><br />
                    {t('resources.mutualaid.undocuscholars-d')}<br />
                    {t('resources.mutualaid.undocuscholars-d1')}<br /><br />

                    <a href="https://www.informedimmigrant.com/guides/coronavirus/#" target="_blank" rel="noopener noreferrer">
                        <b>{t('resources.mutualaid.immigrants')}</b>
                    </a><br /><br />

                    <a href="http://tinyurl.com/nyccovid19need" target="_blank" rel="noopener noreferrer">
                        <b>{t('resources.mutualaid.black-ma-fund')}</b>
                    </a><br /><br />

                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfbzSDdFexOoviuomwsJlnb6wJ-D_24_bUrRJFxf4pp5_aIJg/viewform" target="_blank" rel="noopener noreferrer">
                        <b>{t('resources.mutualaid.black-hammer')}</b>
                    </a><br />
                    {t('resources.mutualaid.black-hammer-d')}
                </div>
                <div className="title">
                    {t('resources.ppe')}
                </div>
                <div className="resources">
                    <br />
                    <a href="https://ppe2nyc.com" target="_blank" rel="noopener noreferrer">
                        Last Mile NYC
                    </a>
                    <br />
                    <a href="https://www.covidcollections.com" target="_blank" rel="noopener noreferrer">
                        Covid Collections
                    </a>
                    <br />
                    <a href="https://findthemasks.com" target="_blank" rel="noopener noreferrer">
                        Find The Masks (map below)
                    </a>
                    <br />
                    <iframe
                        src="https://findthemasks.com/give.html?hide-list=true"
                        height="300"
                        width="100%"></iframe>
                </div>
            </div>
            <div className="right panes">
                <img className="heavy-lifting" src="imgs/Heavy-Lifting.png" alt="Hand holding plastic bags"/>
            </div>
        </div>
    );
}
