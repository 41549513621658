import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';


// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: true,
        lng: "en",

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            // ...
            hashTransKey: function(defaultValue) {
              // return a key based on defaultValue or if you prefer to just remind you should set a key return false and throw an error
            },
            defaultTransParent: 'a', // a valid react element - required before react 16
            transEmptyNodeValue: '', // what to return for empty Trans
            transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
        }
    });

export default i18n;