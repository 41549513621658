import React from 'react';
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';

export default function LandingPage() {
    const { t } = useTranslation();
    return (
        <div className="landing">
            <div className="subtitle">
                {t('landing.blurb')}
            </div>
            <div className="section image">
                <div>
                    <div className="section-title">
                       { t('landing.delivery.question') }
                    </div>
                    <div className="section-content">
                        <p>{ t('landing.delivery.p0') }</p>
                        <p>
                            <Trans i18nKey='landing.delivery.p1'>
                            We make no-contact deliveries of pantry boxes, medical supplies, medication, and any other sort of pickup / drop-off you may need.
                            Please <Link to="/contact">contact us</Link> if we can help.
                            </Trans>
                        </p>
                        <p>
                            <Trans i18nKey='landing.delivery.p2'>
                                Along with general courier work, we help with grocery shopping and food assistance in Brooklyn, Manhattan and Queens. In The Bronx we are currently
                                partnering with Montefiore Hospital and <a href="https://www.facebook.com/bxmpowerment/" target="_blank" rel="noopener noreferrer">BXMpowerment Project</a> to
                                help with their pantry deliveries.
                            </Trans>
                        </p>
                        <p>{ t('landing.delivery.notes.title') }</p>
                        <ul>
                            <li>{ t('landing.delivery.notes.li0') }</li>
                            <li>{ t('landing.delivery.notes.li1') }</li>
                        </ul>
                    </div>
                </div>
                <img className="basket" src="imgs/Basket.png" alt="Bike Basket filled with Groceries"/>
            </div>
            <div className="section">
                <div className="section-title">{ t('landing.food.question') }</div>
                <div className="section-content">
                    <p>
                        <Trans i18nKey='landing.food.p0'>
                            We do help out with grocery shopping and food assistance outside of The Bronx. We will try our best to help you as soon as possible.
                            We also suggest finding a <a href="https://mutualaid.nyc" target="_blank" rel="noopener noreferrer">mutual-aid group</a> near you for local
                            community support.
                        </Trans>
                    </p>
                    <p>
                        <Trans i18nKey='landing.food.p1'>
                            We also suggest checking out your local pantry. Many pantries are accepting new clients and can help you get consistent and free groceries, we ask for you to please check out
                            <a href="https://maps.nyc.gov/foodhelp" target="_blank" rel="noopener noreferrer">FoodHelp NYC</a> or our
                            <Link to="/resources">resources page</Link> for more info. We have provided links to numerous food assistance programs around the city.
                        </Trans>
                    </p>
                    <p>{ t('landing.food.p2') }</p>
                </div>
            </div>
            <div className="section">
                <div className="section-title">{ t('landing.safety.question') }</div>
                <div className="section-content">
                    <p>{ t('landing.safety.p0') }</p>
                    <ul>
                        <li>{ t('landing.safety.li0') }</li>
                        <li>{ t('landing.safety.li1') }</li>
                        <li>{ t('landing.safety.li2') }</li>
                        <li>{ t('landing.safety.li3') }</li>
                        <li>{ t('landing.safety.li4') }</li>
                        <li>{ t('landing.safety.li5') }</li>
                    </ul>
                </div>
            </div>
            <div className="section">
                <div className="section-title">{ t('landing.volunteering.question') }</div>
                <div className="section-content">
                    <p>
                        <Trans i18nKey='landing.volunteering.p0'>
                        We are always looking for more volunteers to join our network. Anyone who is capable of
                        making deliveries can join us. We are mainly cyclists, but love to grow our community of volunteers
                        with cars, motorcycles, etc. Please fill out <a href="https://forms.gle/XpYuRgAKchd4RrZcA" target="_blank" rel="noopener noreferrer">this form</a>
                        to join. You will be added to our Slack where we do most of our communicating.
                        </Trans>
                    </p>
                </div>
            </div>
            <div className="section">
                <div className="section-title">
                    { t('landing.mutualaid.question') }
                </div>
            </div>
            <div className="section-content">
                <p>
                    <Trans i18nKey='landing.mutualaid.p0'>
                    Are you interested in starting something similar in your area?
                    Want to find out information on rent striking?
                    Want to find out info on helping out incarcerated community members and detainees?
                    ant to find other general resources for NYC? For somewhere outside of NYC?
                    You can find all that info on our <Link to="/resources">resources page</Link>
                    </Trans>
                </p>
            </div>
        </div>
    );
}
