import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ContactPage() {
    const { t } = useTranslation();
    return (
        <div className='contact-page'>
            <div className='contact-container'>
                <div className='subtitle'>
                    {t('contact.title')}
                </div>
                <div className='requests'>
                    {t('contact.requests')}<br />
                    <a href="mailto:CoronaCourier@protonmail.com">
                        CoronaCourier@protonmail.com
                    </a><br />
                    <a href="tel:+19293200660">929-320-0660</a>
                </div>
                <div className='community'>
                    {t('contact.community')}<br />
                    <a href="mailto:community@coronacourier.nyc">
                        community@coronacourier.nyc
                    </a>
                </div>
            </div>
            <img className="smiley-logo" src="imgs/Smiley.png" alt="Smiley wearing bike helmet and Corona Couriers mask"/>
        </div>
    );
}


