import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

import firebaseApp from './firebase.js';

export default class Login extends Component {
    uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
    };
    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }} className="subtitle">
                    Login (for operations volunteers only)
                </div>
                <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebaseApp.auth()} />
            </div>
        );
    }
}
